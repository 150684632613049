<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content main_content">
      <div class="bg"></div>
      <div style="position: relative; zIndex: 1;">
        <h1 class="h1">{{$route.query.diagnosis_name}}</h1>
        <div class="title">疾病简介</div>
        <div v-if="data.introduce && !loading" :class="['content-line',{'show_more_content': more_one}]">
           <div class="content" ref="one" v-html="hander_content(data.introduce)"></div>
           <div class="line" v-if="height_one>0 && height_one>=default_height && !more_one"/>
        </div>
        <div class="no-data" v-if="!data.introduce && !loading">
          <img  width="150" src="../../assets/img/no-data.png"/>
            暂无数据
        </div>
        <div class="more" @click="show_more_one" v-if="height_one>0 && height_one>=default_height && !more_one">点击展开全文<img width="13" height="13" src="../../assets/img/down-arrow.png"/></div>
        <div class="title">临床表现</div>
        <div v-if="data.clinical && !loading" :class="['content-line',{'show_more_content': more_two}]" >
          <div class="content" ref="two" v-html="hander_content(data.clinical)"></div>
          <div class="line" v-if="height_two>0 && height_two>=default_height && !more_two"/>
        </div>
        <div class="no-data" v-if="!data.clinical && !loading">
          <img  width="150" src="../../assets/img/no-data.png"/>
            暂无数据
        </div>
        <div class="more" @click="show_more_two" v-if="height_two>0 && height_two>=default_height && !more_two">点击展开全文<img width="13" height="13" src="../../assets/img/down-arrow.png"/></div>
        <div class="title" v-if="labo_list.length> 0">以下指标异常提示该疾病风险</div>
        <template v-if="labo_list.length> 0">
          <div
            v-for="(item, index) in labo_list"
            :key="index"
            @click="jup_laboratory(item)"
            class="labo_item">
              <div class="abnomal-name">
                <span style="flex:1">{{item.laboratory_name}}
                  <span v-if="item.standard_result_type===2">{{item.abnormal ? (item.standard_normalized_quantitative>1 ? '升高' : '降低') : '正常'}}</span>
                  <span v-else class="specimen">{{ item.standard_specimen}}</span>
                  <span v-if="item.standard_result_type===1" :class="['result',{'abnormal':item.abnormal }]" >{{ item.standard_result}}</span>
                </span>
                <img width="10" src="../../assets/img/report-arrow.png"/>
                </div>
              <ul class="indicators" v-if="item.standard_result_type>1">
                <template v-if="item.standard_result_type===2">
                  <li>
                  标本
                    <br/>
                    {{item.standard_specimen}}
                  </li>
                  <li>
                  结果
                    <div class="abnormal-info">
                      <span :class="{'abnormal': !item.standard_normalized_quantitative}">{{ item.standard_result}}</span>
                      <img width="14" height="14" v-if="item.standard_normalized_quantitative > 1" src="../../assets/img/up.png"/>
                      <img width="14" height="14" v-else-if="item.standard_normalized_quantitative < 0" src="../../assets/img/down.png"/>
                    </div>
                  </li>
                  <li class="refer">
                    参考值
                    <br/>
                    {{item.standard_reference}}
                  </li>
                  <li>
                    单位
                    <br/>
                    {{item.standard_unit}}
                  </li>
                </template>
                <li class="narrative" v-else>
                  叙述结果
                  <div> {{item.standard_result}}</div>
                </li>
              </ul>
          </div>
        </template>
      </div>
    </div>
    <div class="foot">
      <div class="item-icon-1">
        <div class="inner">
          <img width="30" src="../../assets/img/report-ai.png"/>
          AI医生
        </div>
      </div>
      <div class="foot-text">如有疑惑咨询医生</div>
      <div class="online" @click="order">在线问诊</div>
    </div>
  </div>
</template>
<script>
import { diagnosis_detail } from './service'

export default {
  data () {
    return {
      data: {},
      more_one: false,
      more_two: false,
      height_one: 0,
      height_two: 0,
      default_height: 200,
      loading: false
    }
  },
  computed: {
    labo_list () {
      let list = []
      if (this.data.source === 1) {
        list = this.data.evidence_graph.laboratories
      } else if (this.data.source === 2) {
        list = this.data.evidence_algorithm.weights
      } else if (this.data.source === 4) {
        list = this.data.evidence_decision.laboratories
      }
      list = list.filter(i=>{
        return i.standard_result
      })
      return list
    },
  },
  components: {
  },
  watch: {
  },
  created () {
    this.init_data()
  },
  mounted() {
  },
  methods: {
    hander_content(val) {
      let text = '&emsp;&emsp;' + val
      text = text.replace(/\n/g,'<br>&emsp;&emsp;')
      return text
    },
    jup_laboratory(i){
      this.$router.push({
        path: '/laboratory-detail',
        query: {
          name: i.laboratory_name,
          specimen: i.standard_specimen
        }
      })
    },
    show_more_one() {
      this.more_one = true
    },
    show_more_two() {
      this.more_two = true
    },
    async init_data () {
      this.loading = true
      try {
        const {data} = await diagnosis_detail({
          diagnosis_name: this.$route.query.diagnosis_name,
          id: this.$route.query.id,
        })
        this.data = data
        this.$nextTick(()=>{
          this.height_one = this.$refs.one ? this.$refs.one.offsetHeight : 0
          this.height_two = this.$refs.two ? this.$refs.two.offsetHeight : 0
        })
      } finally {
        this.loading = false
      }
    },
    async order () {
      let url = 'https://wechat.xmsmjk.com/wechatsehr/wx/#/bookGuide/doctorList?deptCode=1010200&orgCode=350211A1002&orgID=3'
      window.open(url)
    }
  }
}
</script>
<style scoped>
.main_content {
  position: relative;
  padding-bottom: 60px;
  & .bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 160px;
    height: 200px;
    background: url("../../assets/img/arc.png") left bottom no-repeat;
    background-size: 320px;
    z-index: 0;
  }
  & .h1 {
    font-size: 24px;
    line-height: 34px;
    color: #242938;
    margin-top: 30px;
    padding: 0px 16px;
    & .specimen {
      font-size: 16px;
      color: #999;
    }
  }
  & .title {
    font-size: 16px;
    color: #242938;
    margin-top: 35px;
    position: relative;
    margin: 35px 32px 0px;
    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: -14px;
      height: 16px;
      width: 6px;
      border-radius: 6px;
      background: #0088FF;
    }
  }
  & .content-line {
      position: relative;
      max-height: 200px;
      overflow : hidden;
      &.show_more_content {
        max-height: inherit;
      }
     & .content {
      font-size: 14px;
      line-height: 24px;
      color: #565656;
      padding: 0 32px;
      margin-top: 15px;

    }
    & .line {
      height: 25px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      position: absolute;
      bottom: 0;
      right: 32px;
      left:32px;
    }
  }

  & .btn {
    height: 40px;
    line-height: 40px;
    background: #0088FF;
    opacity: 1;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin: 35px 32px 0px;
  }
}
.labo_item {
  padding: 12px;
  margin: 0 30px;
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.08);
  opacity: 1;
  border-radius: 3px;
  & .abnomal-name {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #242938;
      & .specimen {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #242938;
        padding: 0 10px;
      }
      & .result {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        & .abnormal {
          color: #FA5C7C;
        }
      }
    }
    & .abnormal-info {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 18px;
      color: #242938;
      & img {
        margin-left: 2px;
      }
      & .abnormal {
        color: #FA5C7C;
      }
    }
    & .indicators {
      margin-top: 10px;
      display: flex;
      padding: 10px 0;
      min-height: 55px;
      background: #F4F9FE;
      opacity: 1;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #242938;
      & li {
        flex: 1;
        text-align: center;
        &.refer {
          flex:1.5;
        }
        & >div {
          padding-top: 2px;
        }
      }
      & .narrative {
        padding: 0 10px;
        text-align: left;
        line-height: 18px;
      }
    }
  }
  .more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:18px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #1890FF;
    & >img {
      margin-left: 6px;
    }
  }
  .foot {
  z-index: 1;
  height: 90px;
  padding:0 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  position: relative;
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border: 1 solid #ebedf0;
    transform: scale(0.5);
  }
  & .item-icon-1 {
    width: 64px;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    font-weight: 400;
    line-height: 13px;
    color: #FFFFFF;
    margin-top: -10px;
    & .inner {
      width: 55px;
      height: 55px;
      background: #0088FF;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  & .foot-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 55px;
    color: #242938;
    flex: 1;
    padding-left: 15px;
  }
  & .online {
    width: 92px;
    height: 30px;
    background: #1890FF;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 14px;
  }
}
.no-data {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #565656;
  & img {
    margin-bottom: 10px;
  }
}
</style>
