import api from '../../utils/api'

// 详情
export async function diagnosis_detail (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         diagnosis_name: '淋巴细胞计数',
  //         introduce: '淋巴细胞的正常百分比参考值在20%-40%之间。超过40%属于淋巴细胞比值偏高。淋巴细胞比值占白细胞总数的20%-30%之间，分为T细胞、B细胞、杀伤细胞和自然杀伤细胞等四大类。T细胞约占75%，参与细胞免疫。B细胞约占10%-15%，参与体液免疫。当机体被病毒感染时，淋巴细胞数量就会增加，相应的淋巴细胞的比值也会随之增高。当患某些传染病的时候，体内的淋巴细胞也会增多，导致淋巴细胞的比值增高，例如水流行性腮腺炎等一系列传染性疾病。淋巴细胞是免疫细胞，当发现身体淋巴细胞数量增多，比值偏高时，就说明身体处于免疫力低下的时期，容易被病毒感染。应该注意自己的身体状况，增强免疫力，但是不要盲目服药，要遵从医嘱，积极的预防疾病。',
  //         clinical: '①感染性疾病：如麻疹、风疹、流行性腮腺炎等； ②某些血液病：如淋巴细胞性白血病、淋巴瘤等； ③急性传染病恢复期； ④器官移植后的排异反应期等。',
  //         source: 1,
  //         evidence_graph: {
  //           laboratories: [
  //             {
  //               laboratory_name: '血小板',
  //               standard_specimen: '血',
  //               standard_result: '200',
  //               standard_result_type: 2,
  //               standard_reference: '[100, 150]',
  //               standard_unit: 'ml',
  //               standard_normalized_quantitative: 1.5
  //             },
  //             {
  //               laboratory_name: '蛋白质',
  //               standard_specimen: '血',
  //               standard_result: '阳性',
  //               standard_result_type: 1,
  //               standard_reference: '阴性',
  //               standard_unit: 'ml',
  //               standard_normalized_quantitative: 1.5
  //             }
  //           ]
  //         }
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/reports/evidence`, {params: payload})
}
